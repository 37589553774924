<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="salesOrder.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!salesOrder.id"
        @organizationChanged="onOrganizationChanged"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="salesOrder.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="salesOrder.organization.id"
        @locationsChanged="
          (locations) => {
            salesOrder.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      :label="`${$t('COMMON.SERVICECENTER')}`"
      :placeholder="$t('COMMON.SERVICECENTER')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
    >
      <service-center-selector
        :serviceCenter="
          salesOrder.serviceCenter ? salesOrder.serviceCenter.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        @serviceCenterChanged="
          (serviceCenter) => {
            salesOrder.serviceCenter.id = serviceCenter;
            salesOrder.establishment.id = null;
            salesOrder.cafeteria.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.serviceCenter" />

    <base-input
      :label="`${$t('COMMON.ESTABLISHMENT')}`"
      :placeholder="$t('COMMON.ESTABLISHMENT')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
    >
      <establishment-selector
        :establishment="
          salesOrder.establishment ? salesOrder.establishment.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        :filterServiceCenter="salesOrder.serviceCenter.id"
        @establishmentChanged="onEstablishmentChange"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.establishment" />

    <base-input
      :label="`${$t('COMMON.CAFETERIA')}`"
      :placeholder="$t('COMMON.CAFETERIA')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
    >
      <cafeteria-selector
        :cafeteria="salesOrder.cafeteria ? salesOrder.cafeteria.id : null"
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        :filterServiceCenter="salesOrder.serviceCenter.id"
        :filterEstablishment="salesOrder.establishment.id"
        @cafeteriaChanged="
          (cafeteria) => {
            salesOrder.cafeteria.id = cafeteria;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cafeteria" />

    <base-input
      :label="`${$t('SALES_ORDERS.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('SALES_ORDERS.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="salesOrder.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`SALES_ORDERS.SELECT_CUSTOMERS`)} (*)`">
      <customer-selector
        :allowNone="true"
        :customer="salesOrder.recipient.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        :disabled="!!salesOrder.id"
        @customerChanged="
          (customerId, customer) => {
            salesOrder.recipient.id = customerId;
            if (customer) {
              salesOrder.billing_entity_type = customer.customer_type;
              salesOrder.billing_firstname = customer.firstname;
              salesOrder.billing_lastname = customer.lastname;
              salesOrder.billing_company_name = customer.company_name;
              salesOrder.billing_email = customer.email;
              salesOrder.billing_country = customer.country;
              salesOrder.billing_state = customer.state;
              salesOrder.billing_city = customer.city;
              salesOrder.billing_zipcode = customer.zipcode;
              salesOrder.billing_address = customer.address;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.recipient" />

    <base-input :label="`${$t(`COMMON.SOURCE_WAREHOUSE`)}`">
      <warehouse-selector
        :allowNone="true"
        :warehouse="
          salesOrder.sourceWarehouse ? salesOrder.sourceWarehouse.id : null
        "
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        :disabled="!!salesOrder.id"
        @warehouseChanged="
          (warehouseId) => {
            salesOrder.sourceWarehouse.id = warehouseId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.sourceWarehouse" />

    <div class="row">
      <div class="col-6">
        <base-input
          :label="`${$t('SALES_ORDERS.EVENT_DATE')}`"
          :placeholder="$t('SALES_ORDERS.EVENT_DATE')"
        >
          <flat-picker
            :config="{
              locale: $flatPickrLocale(),
              allowInput: true,
              enableTime: false,
              dateFormat: 'Y-m-d',
            }"
            class="form-control datepicker"
            v-model="salesOrder.event_date"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.event_date" />
      </div>
      <div class="col-6">
        <base-input
          :label="`${$t('SALES_ORDERS.EVENT_TIME')}`"
          :placeholder="$t('SALES_ORDERS.EVENT_TIME')"
        >
          <flat-picker
            :config="{
              locale: $flatPickrLocale(),
              allowInput: true,
              enableTime: true,
              noCalendar: true,
            }"
            class="form-control datepicker"
            v-model="salesOrder.event_time"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.event_date" />
      </div>
    </div>

    <h2 class="mt-5">{{ $t("SALES_ORDERS.DELIVERY_LOCATION") }}</h2>
    <html-editor
      v-model="salesOrder.delivery_location"
      @change="onFormChanged()"
    >
    </html-editor>
    <validation-error :errors="apiValidationErrors.delivery_location" />

    <div class="mt-3">
      <base-checkbox
        @change="onFormChanged"
        v-model="salesOrder.has_no_taxes"
        class="mb-3"
      >
        <span class="form-control-label">
          {{ $t("PURCHASES_ORDERS.EXCLUDING_TAXES") }}
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.has_no_taxes" />
    </div>

    <base-input :label="`${$t(`COMMON.PAYMENT_TERM`)} (*)`">
      <payment-term-selector
        :allowNone="false"
        :paymentTerm="salesOrder.paymentTerm ? salesOrder.paymentTerm.id : null"
        :filterable="true"
        :showAll="false"
        :filterOrganization="salesOrder.organization.id"
        @paymentTermChanged="
          (paymentTermId) => {
            salesOrder.paymentTerm.id = paymentTermId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.paymentTerm" />

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="salesOrder.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <h2 class="mt-5">{{ $t("COMMON.TERMS_CONDITIONS") }}</h2>
    <html-editor
      v-model="salesOrder.terms_conditions"
      @change="onFormChanged()"
    >
    </html-editor>
    <validation-error :errors="apiValidationErrors.terms_conditions" />

    <h2 class="mt-5">{{ $t("COMMON.DESCRIPTION") }}</h2>
    <html-editor v-model="salesOrder.description" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.description" />

    <h2 class="mt-5">{{ $t("COMMON.BILLING_INFORMATIONS") }}</h2>
    <base-input
      :label="$t('COMMON.TYPE')"
      :placeholder="$t('COMMON.TYPE')"
      @change="onFormChanged"
    >
      <el-select name="Type" v-model="salesOrder.billing_entity_type">
        <el-option
          :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
        />
        <el-option
          :value="BILLING_ENTITY_TYPE_COMPANY"
          :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.billing_entity_type" />

    <base-input
      :label="$t('COMMON.COMPANY_NAME')"
      v-model="salesOrder.billing_company_name"
      @change="onFormChanged"
      v-if="salesOrder.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY"
    />
    <validation-error :errors="apiValidationErrors.billing_company_name" />

    <div class="row">
      <div class="col">
        <base-input
          :label="$t('COMMON.FIRSTNAME')"
          v-model="salesOrder.billing_firstname"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_firstname" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.LASTNAME')"
          v-model="salesOrder.billing_lastname"
        />
        <validation-error :errors="apiValidationErrors.billing_lastname" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.EMAIL')"
      v-model="salesOrder.billing_email"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.billing_email" />

    <div class="form-wrapper full mb-3">
      <addresses-selector
        @addressInputChangeChanged="
          (value) => {
            salesOrder = {
              ...salesOrder,
              billing_country: value.country,
              billing_state: value.state,
              billing_city: value.city,
              billing_city: value.city,
              billing_zipcode: value.zipcode,
              billing_address: value.address,
            };
            onFormChanged();
          }
        "
      />
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="$t('COMMON.COUNTRY')"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="salesOrder.billing_country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                salesOrder.billing_country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_country" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.STATE')"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="salesOrder.billing_country"
            :state="salesOrder.billing_state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                salesOrder.billing_state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_state" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.CITY')"
          v-model="salesOrder.billing_city"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_city" />
      </div>
      <div class="col">
        <base-input
          :label="$t('COMMON.ZIPCODE')"
          v-model="salesOrder.billing_zipcode"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_zipcode" />
      </div>
    </div>

    <base-input
      :label="$t('COMMON.ADDRESS')"
      v-model="salesOrder.billing_address"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.billing_address" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          salesOrder.id
            ? $t("SALES_ORDERS.EDIT_SALES_ORDER")
            : $t("SALES_ORDERS.ADD_SALES_ORDER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Option, Select } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BILLING_ENTITY_TYPE_COMPANY,
  BILLING_ENTITY_TYPE_INDIVIDUAL,
} from "@/constants/billingInformations";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import {
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_PROFESSIONAL,
  ESTABLISHMENT_TYPE_SECONDARY,
} from "@/constants/establishments";
import AddressesSelector from "@/components/AddressesSelector.vue";
import PaymentTermSelector from "@/components/PaymentTermSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    CustomerSelector,
    CountrySelector,
    StateSelector,
    ServiceCenterSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    WarehouseSelector,
    AddressesSelector,
    PaymentTermSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["salesOrderData", "formErrors", "loading"],

  data() {
    return {
      salesOrder: null,
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
      establishmentTypes: [
        ESTABLISHMENT_TYPE_PROFESSIONAL,
        ESTABLISHMENT_TYPE_SECONDARY,
        ESTABLISHMENT_TYPE_ELEMENTARY,
      ],
    };
  },

  created() {
    let salesOrderData = { ...this.salesOrderData };
    salesOrderData = this.$fillUserOrganizationData(salesOrderData);
    salesOrderData = this.$fillUserCafeteriasData(salesOrderData);
    salesOrderData.event_time = salesOrderData.event_date;
    if (!salesOrderData.id) {
      salesOrderData.terms_conditions =
        this.organizationConfig.DEFAULT_SALES_ORDER_TERMS;
      salesOrderData.description =
        this.organizationConfig.DEFAULT_SALES_ORDER_DESCRIPTION;
      salesOrderData.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_SALES_PAYMENT_TERMS,
      };
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.salesOrder = salesOrderData;
  },

  computed: {
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  methods: {
    async handleSubmit() {
      let salesOrderData = cloneDeep(this.salesOrder);
      salesOrderData.expiration_time = moment(
        salesOrderData.expiration_time
      ).toISOString();
      salesOrderData.event_date = moment(
        salesOrderData.event_date
      ).toISOString();
      salesOrderData.event_date = this.getEventDate(salesOrderData);
      // console.log(salesOrderData.event_date);
      salesOrderData = this.$fillUserOrganizationData(salesOrderData);
      salesOrderData = this.$fillUserCafeteriasData(salesOrderData);
      delete salesOrderData.salesInvoice;
      if (!salesOrderData.billing_company_name) {
        salesOrderData.billing_company_name = "N/A";
      }
      if (salesOrderData.serviceCenter) {
        if (!salesOrderData.serviceCenter.id) {
          delete salesOrderData.serviceCenter;
        }
      }
      if (salesOrderData.establishment) {
        if (!salesOrderData.establishment.id) {
          delete salesOrderData.establishment;
        }
      }
      if (salesOrderData.cafeteria) {
        if (!salesOrderData.cafeteria.id) {
          delete salesOrderData.cafeteria;
        }
      }
      this.$emit("salesOrderSubmitted", salesOrderData);
    },

    getEventDate(salesOrderData) {
      const eventDateString = moment(salesOrderData.event_date).format(
        "YYYY-MM-DD"
      );
      const eventTimeString = moment(salesOrderData.event_time).isValid()
        ? moment(salesOrderData.event_time).format("HH:mm:00")
        : salesOrderData.event_time ?? "00:00:00";
      const datetimeString = eventDateString + "T" + eventTimeString;
      return this.$getUtcDateTimeString(datetimeString);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onEstablishmentChange(establishmentId, establishment) {
      this.salesOrder.establishment.id = establishmentId;
      this.salesOrder.has_no_taxes = this.establishmentTypes.includes(
        establishment.establishment_type
      );
      this.salesOrder.cafeteria.id = null;
      this.onFormChanged();
    },

    async onOrganizationChanged(organizationId) {
      if (!organizationId) {
        return;
      }
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        organizationId
      );
      this.salesOrder.organization.id = organizationId;
      this.salesOrder.allowedLocations = [];
      this.salesOrder.recipient.id = null;
      this.salesOrder.serviceCenter.id = null;
      this.salesOrder.establishment.id = null;
      this.salesOrder.cafeteria.id = null;
      this.salesOrder.terms_conditions =
        this.organizationConfig.DEFAULT_SALES_ORDER_TERMS;
      this.salesOrder.description =
        this.organizationConfig.DEFAULT_SALES_ORDER_DESCRIPTION;
      this.salesOrder.paymentTerm = {
        type: "payment-terms",
        id: this.organizationConfig.DEFAULT_SALES_PAYMENT_TERMS,
      };
      this.onFormChanged();
      window.scrollTo({ top: 0, behavior: "smooth" });
      swal.close();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },

    salesOrderData(salesOrderData) {
      if (salesOrderData) {
        this.salesOrder = cloneDeep(this.salesOrderData);
        this.salesOrder = this.$fillUserOrganizationData(this.salesOrder);
        this.salesOrder = this.$fillUserCafeteriasData(this.salesOrder);
        this.salesOrder.event_time = salesOrderData.event_date;
        if (!this.salesOrder.organization) {
          this.salesOrder.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.salesOrder.serviceCenter) {
          this.salesOrder.serviceCenter = {
            type: "service-centers",
            id: null,
          };
        }
        if (!this.salesOrder.establishment) {
          this.salesOrder.establishment = {
            type: "establishments",
            id: null,
          };
        }
        if (!this.salesOrder.cafeteria) {
          this.salesOrder.cafeteria = {
            type: "cafeterias",
            id: null,
          };
        }
      }
    },
  },
};
</script>
